import React, { Component, Suspense } from "react";
//Header
import Header from "./Components/Header/Header";
//Styling
//React Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Bootstrap
import "bootstrap/dist/css/bootstrap.css";
//footer
import Footer from "./Components/Footer/Footer";
//Home module
import Home from "./Screens/Home/Home";
//Service Listing

import Landing from "./Screens/Home/Landing";

import ProjectList from "./Screens/Project/List/ProjectList";
import ServiceDetail from "./Screens/Service/details/ServiceDetail";
import ProjectDetail from "./Screens/Project/details/ProjectDetail";
import Login from "./Authenticator/Login/Login";
import Signup from "./Authenticator/Signup/Signup";
import ServiceList from "./Screens/Service/List/ServiceList";
import CustomerDashboard from "./Screens/Dashboard/Customer/CustomerDashboard/Customer";
import Proposals from "./Screens/Dashboard/Customer/Proposals/Proposals";
import Projects from "./Screens/Dashboard/Customer/Projects/Projects";
import ProtectedRoutes from "./protected.routes";
import AddProject from "./Screens/Dashboard/Customer/Projects/AddProject";
import Category from "./Screens/Category/Category";
import CustomerChat from "./Screens/Dashboard/Customer/Chat/CustomerChat";
import UpdateProject from "./Screens/Dashboard/Customer/Projects/UpdateProject";
import Settings from "./Screens/Dashboard/Customer/Settings/Settings";
import Address from "./Screens/Dashboard/Customer/Settings/Address";
import Payment from "./Screens/Dashboard/Customer/Settings/Payment";
import ChangePassword from "./Screens/Dashboard/Customer/Settings/ChangePassword";
import Security from "./Screens/Dashboard/Customer/Settings/Security";
import EditAddress from "./Screens/Dashboard/Customer/Settings/EditAddress";
import AddAddress from "./Screens/Dashboard/Customer/Settings/AddAddress";
import ProfileUpdate from "./Screens/Dashboard/Customer/Settings/ProfileUpdate";

import Transaction from "./Screens/Dashboard/Customer/TransactionHistory/Transaction";
import SettingTabs from "./Screens/Dashboard/Customer/Settings/SettingTabs";

// vendor
import VendorDashboard from "./Screens/Dashboard/Vendor/Dashboard/VendorDashboard";
import VendorChat from "./Screens/Dashboard/Vendor/Chat/Index.js";
import ServiceAdd from "./Screens/Dashboard/Vendor/Service/Create/Add";
import ServiceUpdate from "./Screens/Dashboard/Vendor/Service/Create/Update";
import VendorServices from "./Screens/Dashboard/Vendor/Service/List/Index";
import ProjectProposals from "./Screens/Dashboard/Vendor/Project/List/Index";
import VendorSecurity from "./Screens/Dashboard/Vendor/Setting/VendorSecurity";
import VendorTransaction from "./Screens/Dashboard/Vendor/Transaction/VendorTransaction";
import VendorProfile from "./Screens/Dashboard/Vendor/MyProfile/VendorProfile";
import VendorProfileTabs from "./Screens/Dashboard/Vendor/MyProfile/VendorProfileTabs";
import VendorWalletScreen from "./Screens/Dashboard/Vendor/wallet/VendorWalletScreen";
import ServiceRequest from "./Screens/Dashboard/Vendor/Service/ServiceRequest";
import Support from "./Support/Support";
import { useEffect } from "react";

//import Admin from "./Screens/Admin/AdminLogin/Admin";
//import AdminLogin from "./Screens/Admin/AdminLogin/AdminLogin";
//import CategoryAdmin from "./Screens/Admin/Components/AdminPanel/CategoryScreen/CategoryAdmin";
//import AdminDashboard from "./Screens/Admin/AdminLogin/Admin";
import AdminProject from "./Screens/Admin/Project/Index";
import CnV from "./Screens/Admin/CnV/Index";

import Employee from "./Screens/Admin/Employee/Index";
import AddEmployee from "./Screens/Admin/Employee/add_employee";

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);
function App() {
	//useEffect(() => {}, []);

	return (
		<div className="App index-page">
			<BrowserRouter>
				<Header />
				<Support />

				<Suspense fallback={loading}>
					{/* protected routes */}

					<Routes>
						<Route path="/" element={<Home />} />
						<Route
							path="/"
							name="dasboard"
							element={<ProtectedRoutes />}
						>

							<Route
								exact
								path="/customer_dashboard"
								element={<CustomerDashboard />}
							/>
							<Route
								exact
								path="/customer_proposals"
								element={<Proposals />}
							/>
							<Route
								exact
								path="/customer_proposals/:id"
								element={<Proposals />}
							/>
							<Route
								exact
								path="/customer_projects/:type"
								element={<Projects />}
							/>
							<Route
								exact
								path="/transaction_history"
								element={<Transaction />}
							/>
							<Route
								exact
								path="/customer_projects"
								element={<Projects />}
							/>
							{/* <Route exact path="/customer_settings" element={<Settings />} /> */}
							<Route
								exact
								path="/customer_address"
								element={<Address />}
							/>
							<Route
								exact
								path="/customer_payment"
								element={<Payment />}
							/>
							<Route
								exact
								path="/customer_change_password"
								element={<ChangePassword />}
							/>
							<Route
								exact
								path="/customer_security"
								element={<Security />}
							/>
							<Route
								exact
								path="/customer_editaddress/:address_id"
								element={<EditAddress />}
							/>
							<Route
								exact
								path="/customer_settings/:tab?/:subtab?/:address_id?"
								element={<SettingTabs />}
							/>
							<Route
								exact
								path="/profile"
								element={<ProfileUpdate />}
							/>
							<Route
								exact
								path="/address/add"
								element={<AddAddress />}
							/>
							<Route
								exact
								path="/customer_project_add"
								element={<AddProject />}
							/>
							<Route
								exact
								path="/project/edit/:project_id"
								element={<UpdateProject />}
							/>
							<Route
								exact
								path="/customer_chat/:id"
								element={<CustomerChat />}
							/>
						</Route>

						<Route
							path="/vendor/"
							name="dasboard"
							element={<ProtectedRoutes />}
						>
							<Route
								exact
								path="/vendor/dashboard"
								element={<VendorDashboard />}
							/>
							<Route
								exact
								path="/vendor/chat/:id"
								element={<VendorChat />}
							/>
							<Route
								exact
								path="/vendor/service/add"
								element={<ServiceAdd />}
							/>
							<Route
								exact
								path="/vendor/service/edit/:service_id"
								element={<ServiceUpdate />}
							/>
							<Route
								exact
								path="/vendor/services"
								element={<VendorServices />}
							/>
							<Route
								exact
								path="/vendor/service_requests"
								element={<ServiceRequest />}
							/>

							<Route
								exact
								path="/vendor/services/:type"
								element={<VendorServices />}
							/>
							<Route
								exact
								path="/vendor/projectproposals"
								element={<ProjectProposals />}
							/>
							<Route
								exact
								path="/vendor/projectproposals/:type"
								element={<ProjectProposals />}
							/>
							<Route
								exact
								path="/vendor/wallet"
								element={<VendorWalletScreen />}
							/>
							
							<Route
								exact
								path="/vendor/profile/:tab?/:subtab?/:address_id?"
								element={<VendorProfileTabs />}
							/>

							<Route
								exact
								path="/vendor/transaction_history"
								element={<VendorTransaction />}
							/>

							<Route
								exact
								path="/vendor/security"
								element={<VendorSecurity />}
							/>
							
						</Route>

						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />

						<Route path="/services" element={<ServiceList />} />
						<Route path="/landing" element={<Landing />} />
						<Route path="/services/:id" element={<ServiceList />} />
						<Route path="/projects" element={<ProjectList />} />
						<Route
							path="/vendor_detail/:id"
							element={<ServiceDetail />}
						/>
						<Route
							path="/project_detail/:id"
							element={<ProjectDetail />}
						/>

{/* 
						<Route
								exact
								path="/admin_dashboard"
								element={<Admin />}
							/> */}
						
						<Route path="/admin/project" element={<AdminProject />} />
						<Route path="/admin/cnv" element={<CnV />} />
						<Route path="/admin/employee" element={<Employee />} />
						<Route path="/admin/add_employee" element={<AddEmployee />} />
						
						{/* <Route path="/admindashboard" element={<AdminDashboard />} />
						
						<Route path="/category" element={<Category />} />
						<Route path="/categoryadmin" element={<CategoryAdmin />} /> 
						<Route path="/adminlogin" element={<AdminLogin />} /> */}


					</Routes>
				</Suspense>
			</BrowserRouter>
			{/* <ProjectListing /> */}
			<Footer />
		</div>
	);
}

export default App;