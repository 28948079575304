import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BsBell } from "react-icons/bs";
import { BsChatLeftText } from "react-icons/bs";
import { MDBContainer } from "mdb-react-ui-kit";
import GeegrLogo from "../../assets/images/geegrLogo.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signout } from "../../redux/actions/UserActions";
import Notification from "../Notification/Notification";

import logo from "../../assets/images/logo.svg";

import { useNavigate, useLocation } from "react-router-dom";
function Header() {
  const dispatch = useDispatch();
  
  const { userSignin: userInfo } = useSelector((state) => state);
  console.log(userInfo, "at header (REDUX)");
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation()
  // useEffect(() => {
  //   if (userInfo.isLoggedIn) {
  //     setLoggedIn(true);
  //   }
  // }, [userInfo]);
  return (
    <>
    {userInfo?.isLoggedIn ? (
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        style={{ minHeight: "8vh", height: "10vh", background: "white" }}
        fixed="top"
        className="shadow"
      >
        <Container>
          {/* logo */}
          <Navbar.Brand href="/" style={{ color: "#6A2FF9" }}>
            <img className="logoPng" src={GeegrLogo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/landing">Landing</Nav.Link> 
              <Nav.Link href="/services">Services</Nav.Link> 
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/customer_dashboard">Dashboard</Nav.Link>
              { location.pathname.startsWith('/vendor/')
                ?<Nav.Link href="/customer_dashboard">Go to customer</Nav.Link>
                :<Nav.Link href="/vendor/dashboard">Go to vendor</Nav.Link>
              }
            </Nav>

            <Nav
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* notification  */}
              <div className="flex-align-row">
              {userInfo?.isLoggedIn ? (
                <Nav.Link href="#deets">
                  <NavDropdown title={<BsBell />} id="collasible-nav-dropdown">
                    <Notification/>
                  </NavDropdown>
                </Nav.Link>) : "" }

                {/* message dropdown */}
                <Nav.Link eventKey={2} href="#memes">
                  <NavDropdown
                    title={<BsChatLeftText />}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item href="#action/3.1">
                      action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Something
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav.Link>
              </div>
              {/* avatar */}
              {userInfo?.isLoggedIn ? (
                <>
                  <Nav.Link 
                    href="/signup"
                    onClick={() => {
                      dispatch(signout());
                    }}
                  >
                    Logout
                  </Nav.Link>
                  <Nav.Link eventKey={2} href="#memes">
                    <MDBContainer
                      className="d-flex justify-content-center "
                      style={{ height: "6vh", position: "relative" }}
                    >
                      <span className="mx-2 mt-2">{userInfo?.userInfo?.data?.user?.first_name}</span>
                      <img
                        src={userInfo?.userInfo?.data?.user?.profile_image ? `${process.env.REACT_APP_URL}/uploads/${userInfo?.userInfo?.data?.user?.profile_image?.[0]}` : `https://mdbcdn.b-cdn.net/img/new/avatars/2.webp`}
                        
                        className="rounded-circle"
                        alt="Avatar"
                      />
                      <span className="status-icon"></span>
                    </MDBContainer>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link href="/login"> Login</Nav.Link>
                  {/* {<>/</>}
                  <Nav.Link href="/signup">Sign up</Nav.Link> */}
                </>
              )}

              {/* avatar ends */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    ):(

      <header id="header" class="header d-flex align-items-center sticky-top">
      <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
  
        <a href="index.html" class="logo d-flex align-items-center me-auto">
          <img src={{logo}} alt="" />
        </a>
  
        <nav id="navmenu" class="navmenu">
          <ul>
            <li><a href="/#hero" class="active">Home</a></li>
            <li><a href="/#services">About</a></li>
            <li><a href="/#contact">Contact Us</a></li>
          </ul>
          <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
  
        <a class="btn-getstarted login-btn" href="/login">Login</a>
        <a class="btn-getstarted Join-btn" href="/signup">Join Geegr</a>
  
  
      </div>
    </header>

    )}
    </>
  );
}

export default Header;