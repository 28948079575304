import React, { useState } from "react";
import CategoryList from "./components/CategoryList";
import HeroSection from "./components/HeroSection";
import useFetch from "../../Hooks/useFetch";
import useFetchAndPost from "../../Hooks/useFetchAndPost";
import VendorCard from "./components/VendorCard";
import Wrapper from "../../Utlilities/Wrapper";

import Landing from "./Landing";
import { useSelector } from "react-redux";
function Home() {
  //csutom - fetch hook
  const { error, isPending, data } = useFetch(
    process.env.REACT_APP_URL + "/front/"
  );
  //const {data:data}=useGetPosts({url: 'front'})
  const wrapperHeight = "10vh";
  const { userSignin: userInfo } = useSelector((state) => state);

  return (
<>
    {userInfo?.isLoggedIn ? (

        <>
          <Wrapper wrapperHeight={wrapperHeight} />
          <HeroSection />
          <CategoryList homeData={data?.data?.categories} />
        </>

      ):(
        <>
        <Landing />
        </>
    )}
</>
  );
}

export default Home;